import { itemTypes } from '@constants/index';

export const scheduleItemStatus = {
  MISSED: 1,
  WATCHED_OR_ATTEMPTED: 2,
  UPCOMING: 3,
  CANCELLED: 4
};

export const ScheduleItemStatusLabel = {
  [scheduleItemStatus.MISSED]: 'missed',
  [scheduleItemStatus.WATCHED_OR_ATTEMPTED]: 'completed',
  [scheduleItemStatus.UPCOMING]: 'upcoming',
  [scheduleItemStatus.CANCELLED]: 'cancelled'
};

export const scheduleItemTypes = {
  CLASS: 1,
  QUIZ: 2,
  COMBAT: 3, // removed in planner v2
  TEST: 4,
  LIVE_INTERACTION: 5,
  OPENHOUSE: 6, // removed in planner v2
  DAILY_PRACTICE_REMINDER: 7 // removed in planner v2
};

export const ScheduleItemTypeLabel = {
  [scheduleItemTypes.CLASS]: itemTypes.CLASS,
  [scheduleItemTypes.QUIZ]: itemTypes.QUIZ,
  [scheduleItemTypes.TEST]: itemTypes.TEST,
  [scheduleItemTypes.COMBAT]: itemTypes.COMBAT
};

export const ScheduleItemRankLabel = {
  [scheduleItemTypes.CLASS]: 'Lesson',
  [scheduleItemTypes.QUIZ]: 'Quiz',
  [scheduleItemTypes.COMBAT]: 'Combat',
  [scheduleItemTypes.TEST]: 'Test',
  [scheduleItemTypes.LIVE_INTERACTION]: 'Live Interaction',
  [scheduleItemTypes.OPENHOUSE]: 'Openhouse'
};

// categorized based on their height
export const plannerEventTypes = {
  CLASS: 'class',
  CLASS_WITH_PRACTICE: 'class_with_practice',
  TEST: 'test',
  TEST_WITH_EDUCATOR: 'test_with_educator',
  QUIZ: 'quiz',
  COMBAT: 'combat',
  LIVE_INTERACTION: 'live_interaction',
  DAILY_PRACTICE_REMINDER: 'daily_practice_reminder',
  OPENHOUSE: 'openhouse',
  OFFLINE_TEST: 'offline_test'
};
