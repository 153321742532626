/* eslint-disable max-lines */
export const authTypes = {
  AUTHENTICATE: 'AUTHENTICATE',
  DEAUTHENTICATE: 'DEAUTHENTICATE',
  ME: 'ME',
  FOLLOWED_GOALS: 'FOLLOWED_GOALS',
  FETCH_COUNTRY_STATES: 'FETCH_COUNTRY_STATES',
  FETCH_STATES: 'FETCH_STATES',
  FETCH_CREDENTIALS: 'FETCH_CREDENTIALS',
  FETCH_BIO_RECOMMENDATION: 'FETCH_BIO_RECOMMENDATION',
  SELECTED_GOAL: 'SELECTED_GOAL',
  RESEND_OTP: 'RESEND_OTP',
  LOGIN: 'LOGIN',
  RESET_OTP_RESPONSE: 'RESET_OTP_RESPONSE',
  RESET_VERIFY_OTP_RESPONSE: 'RESET_VERIFY_OTP_RESPONSE',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_CREDENTIALS: 'UPDATE_CREDENTIALS',
  UNLOCK_FREE_PLAN: 'UNLOCK_FREE_PLAN',
  RESET_NETWORK_CALLS_ERROR: 'RESET_NETWORK_CALLS_ERROR',
  SEND_MAIL_OTP: 'SEND_MAIL_OTP',
  VERIFY_MAIL_OTP: 'VERIFY_MAIL_OTP',
  SEND_MOBILE_OTP: 'SEND_MOBILE_OTP',
  VERIFY_MOBILE_OTP: 'VERIFY_MOBILE_OTP',
  FETCH_FEEDBACK_OPTIONS: 'FETCH_FEEDBACK_OPTIONS',
  SUBMIT_FEEDBACK: 'SUBMIT_FEEDBACK',
  LATEST_STREAK: 'LATEST_STREAK',
  UPDATE_AVATAR: 'UPDATE_AVATAR',
  FETCH_RENEWAL_TOUCHPOINT_DATA: 'FETCH_RENEWAL_TOUCHPOINT_DATA',
  FETCH_SETTINGS_MANAGE_UPDATES: 'FETCH_SETTINGS_MANAGE_UPDATES',
  FETCH_REFERRAL_DETAILS: 'FETCH_REFERRAL_DETAILS',
  FETCH_REFERRAL_LIST: 'FETCH_REFERRAL_LIST',
  CHANGE_MANAGE_UPDATE_PREFERENCES: 'CHANGE_MANAGE_UPDATE_PREFERENCES',
  FETCH_INTERCOM_TOKEN: 'FETCH_INTERCOM_TOKEN',
  PRE_PAYMENT_PLUS_SUBSCRIPTION_UIDS: 'PRE_PAYMENT_PLUS_SUBSCRIPTION_UIDS',
  GENERATE_CUSTOM_CODE: 'GENERATE_CUSTOM_CODE',
  GET_CUSTOM_CODE_DATA: 'GET_CUSTOM_CODE_DATA',
  SET_LOCAL_PHONE: 'SET_LOCAL_PHONE',
  SET_LOCAL_EMAIL_NAME: 'SET_LOCAL_EMAIL_NAME',
  FETCH_PURCHASED_ITEMS: 'FETCH_PURCHASED_ITEMS',
  FETCH_STORE_MY_PURCHASE_DETAILS: 'FETCH_STORE_MY_PURCHASE_DETAILS'
};

export const careersTypes = {
  GET_JOB_DETAILS: 'GET_JOB_DETAILS',
  GET_CMS_DATA: 'GET_CMS_DATA'
};
export const courseTypes = {
  UPDATE_COURSES: 'UPDATE_COURSES',
  UPDATE_FEEDROWS: 'UPDATE_FEEDROWS',
  FETCH_FREE_COURSES: 'FETCH_FREE_COURSES',
  FETCH_FREE_GOAL_COURSES: 'FETCH_FREE_GOAL_COURSES',
  FETCH_COURSE_INFO: 'FETCH_COURSE_INFO',
  FETCH_SPECIAL_CLASSES: 'FETCH_SPECIAL_CLASSES',
  FETCH_SPECIAL_CLASSES_TYPE: 'FETCH_SPECIAL_CLASSES_TYPE',
  FETCH_FREE_CONTINUE_LEARNING: 'FETCH_CONTINUE_LEARNING',
  FETCH_MY_LIBRARY: 'FETCH_MY_LIBRARY',
  TOGGLE_COURSE_SAVED: 'TOGGLE_COURSE_SAVED',
  FETCH_ENROLLED_COURSES: 'FETCH_ENROLLED_COURSES',
  FETCH_RECOMMENDED_COURSES: 'FETCH_RECOMMENDED_COURSES',
  FETCH_COMPLETED_COURSES: 'FETCH_COMPLETED_COURSES',
  FETCH_MORE_COURSES: 'FETCH_MORE_COURSES',
  FETCH_MORE_COURSES_FILTERS: 'FETCH_MORE_COURSES_FILTERS',
  FETCH_ENROLLED_COURSES_FILTERS: 'FETCH_ENROLLED_COURSES_FILTERS',
  FETCH_SEE_ALL_FEED: 'FETCH_SEE_ALL_FEED',
  TOGGLE_COURSE_ENROLLED: 'TOGGLE_COURSE_ENROLLED',
  FETCH_FREE_COURSE_ITEM: 'FETCH_FREE_COURSE_ITEM',
  FETCH_RELEVANT_COURSES: 'FETCH_RELEVANT_COURSES',
  FETCH_COURSE_REVIEWS: 'FETCH_COURSE_REVIEWS',
  SUBMIT_REVIEW: 'SUBMIT_REVIEW',
  FETCH_FREE_COURSES_OF_A_GOAL: 'FETCH_FREE_COURSES_OF_A_GOAL',
  FETCH_ONBOARDING_RECOMMENDED_COURSES: 'FETCH_ONBOARDING_RECOMMENDED_COURSES',
  FETCH_PREVIOUS_ENROLLED_COURSES: 'FETCH_PREVIOUS_ENROLLED_COURSES',
  TOGGLE_LIKE: 'TOGGLE_LIKE',
  ADD_COMMENT: 'ADD_COMMENT',
  FETCH_COMMENTS: 'FETCH_COMMENTS',
  INCREASE_COMMENT: 'INCREASE_COMMENT',
  TOGGLE_COMMENT_LIKE: 'TOGGLE_COMMENT_LIKE',
  ADD_REPLY: 'ADD_REPLY',
  FETCH_FILTERS: 'FETCH_FILTERS',
  UPDATE_FILTER: 'UPDATE_FILTER',
  CHECK_COURSE_ENROLL: 'CHECK_COURSE_ENROLL',
  GET_FILTERS_FOR_EDUCATORS_LIST: 'GET_FILTERS_FOR_EDUCATORS_LIST',
  ADD_COMPLETED_COURSE_TO_PLANNER: 'ADD_COMPLETED_COURSE_TO_PLANNER',
  REMOVE_COURSE_FROM_PLANNER: 'REMOVE_COURSE_FROM_PLANNER',
  GET_PLANNER_SCHEDULE: 'GET_PLANNER_SCHEDULE',
  GET_ALL_POPULAR_SUBJECTS: 'GET_ALL_POPULAR_SUBJECTS',
  GET_ALL_SPECIAL_CLASSES: 'GET_ALL_SPECIAL_CLASSES'
};

export const educatorTypes = {
  UPDATE_USERS: 'UPDATE_USERS',
  FETCH_EDUCATOR_LEADERBOARD: 'FETCH_EDUCATOR_LEADERBOARD',
  FETCH_PROFILE_INFO: 'FETCH_PROFILE_INFO',
  FETCH_PROFILE_STATS: 'FETCH_PROFILE_STATS',
  FETCH_PROFILE_FEED: 'FETCH_PROFILE_FEED',
  FETCH_FILTERED_COURSES: 'FETCH_FILTERED_COURSES',
  FETCH_EDUCATOR_COURSES_FILTERS: 'FETCH_EDUCATOR_COURSES_FILTERS',
  FETCH_EDUCATOR_TESTIMONIALS: 'FETCH_EDUCATOR_TESTIMONIALS',
  TOGGLE_FOLLOWING_STATUS: 'TOGGLE_FOLLOWING_STATUS',
  FETCH_LEARNER_COURSES: 'FETCH_LEARNER_COURSES',
  FETCH_LEARNER_MILESTONES: 'FETCH_LEARNER_MILESTONES',
  FETCH_LEARNER_FOLLOWING_LIST: 'FETCH_LEARNER_FOLLOWING_LIST',
  FETCH_LEARNER_LEADERBOARD: 'FETCH_LEARNER_LEADERBOARD',
  FETCH_FAVOURITE_EDUCATORS: 'FETCH_FAVOURITE_EDUCATORS',
  WRITE_DEDICATION: 'WRITE_DEDICATION',
  FETCH_EDUCATOR_DASHBOARD_COURSES: 'FETCH_EDUCATOR_DASHBOARD_COURSES',
  FETCH_EDUCATOR_SCHEDULED_CLASSES: 'FETCH_EDUCATOR_SCHEDULED_CLASSES',
  UPDATE_COURSE_DETAILS: 'UPDATE_COURSE_DETAILS',
  UPDATE_COURSE_DETAILS_DATA: 'UPDATE_COURSE_DETAILS_DATA',
  UPDATE_COURSE_DROPDOWN_FIELDS: 'UPDATE_COURSE_DROPDOWN_FIELDS',
  FETCH_ALL_LANGUAGES: 'FETCH_ALL_LANGUAGES',
  SEARCH_GOAL: 'SEARCH_GOAL',
  UPDATE_COURSE_AUTOCOMPLETE_FIELDS: 'UPDATE_COURSE_AUTOCOMPLETE_FIELDS',
  SEARCH_TOPIC: 'SEARCH_TOPIC',
  SEARCH_EXAM: 'SEARCH_EXAM',
  SEARCH_EDUCATOR: 'SEARCH_EDUCATOR',
  CREATE_PLUS_COURSE: 'CREATE_PLUS_COURSE',
  EDIT_PLUS_COURSE: 'EDIT_PLUS_COURSE',
  REMOVE_TAG: 'REMOVE_TAG',
  UPDATE_CHECK_BOX: 'UPDATE_CHECK_BOX',
  CREATE_SPECIAL_CLASS: 'CREATE_SPECIAL_CLASS',
  EDIT_SPECIAL_CLASS: 'EDIT_SPECIAL_CLASS',
  UPLOAD_THUMBNAIL: 'UPLOAD_THUMBNAIL',
  SET_BROCHURE: 'SET_BROCHURE',
  REMOVE_BROCHURE: 'REMOVE_BROCHURE',
  FETCH_COURSE_TYPES: 'FETCH_COURSE_TYPES',
  SET_COURSE_TYPE: 'SET_COURSE_TYPE',
  SEACRH_CONCEPT: 'SEACRH_CONCEPT',
  UPDATE_SESSION_VALUE: 'UPDATE_SESSION_VALUE',
  UPDATE_SESSION_CONCEPTS: 'UPDATE_SESSION_CONCEPTS',
  REMOVE_CONCEPT: 'REMOVE_CONCEPT',
  FETCH_CO_AUTHOR: 'FETCH_CO_AUTHOR',
  ADD_ITEM: 'ADD_ITEM',
  EDIT_SESSION: 'EDIT_SESSION',
  DELETE_ITEM: 'DELETE_ITEM',
  SET_SESSION_EDUCATOR: 'SET_SESSION_EDUCATOR',
  SET_TA_EDUCATOR_SC: 'SET_TA_EDUCATOR_SC',
  SETUP_EDIT_SESSION: 'SETUP_EDIT_SESSION',
  CREATE_TEST_SERIES_COURSE: 'CREATE_TEST_SERIES_COURSE',
  EDIT_TEST_SERIES_COURSE: 'EDIT_TEST_SERIES_COURSE',
  STATE_CHANGE: 'STATE_CHANGE',
  FETCH_EDUCATOR_DEDICATIONS: 'FETCH_EDUCATOR_DEDICATIONS',
  FETCH_EDUCATOR_WATCH_MINUTES: 'FETCH_EDUCATOR_WATCH_MINUTES',
  FETCH_EDUCATOR_VIEWS_STATS: 'FETCH_EDUCATOR_VIEWS_STATS',
  FETCH_EDUCATOR_ALERTS: 'FETCH_EDUCATOR_ALERTS',
  FETCH_NSCLL_DATA: 'FETCH_NSCLL_DATA',
  FETCH_EDUCATOR_REFERRALS: 'FETCH_EDUCATOR_REFERRALS',
  FETCH_EDUCATOR_LIVE_HOURS: 'FETCH_EDUCATOR_LIVE_HOURS',
  FETCH_EDUCATOR_STATISTICS: 'FETCH_EDUCATOR_STATISTICS',
  FETCH_EDUCATOR_LIVE_CLASSES: 'FETCH_EDUCATOR_LIVE_CLASSES',
  SET_CREATE_EDIT_SESSION_ERROR_FLAG: 'SET_CREATE_EDIT_SESSION_ERROR_FLAG',
  CLEAR_ADD_ITEM_FORM: 'CLEAR_ADD_ITEM_FORM',
  CLEAR_COURSE_FIELDS: 'CLEAR_COURSE_FIELDS',
  FETCH_EDU_LIVE_CLASS_URL: 'FETCH_EDU_LIVE_CLASS_URL',
  FETCH_COURSE_STATS: 'FETCH_COURSE_STATS',
  FETCH_COURSE_RATINGS: 'FETCH_COURSE_RATINGS',
  FETCH_COURSE_FEEDBACK: 'FETCH_COURSE_FEEDBACK',
  FETCH_LESSON_METRICS: 'FETCH_LESSON_METRICS',
  FETCH_EDU_LESSON_FEEDBACK: 'FETCH_EDU_LESSON_FEEDBACK',
  UPDATE_RESCHEDULE_TEXT_FIELD: 'UPDATE_RESCHEDULE_TEXT_FIELD',
  UPDATE_RESCHEDULE_DROPDOWN_FIELDS: 'UPDATE_RESCHEDULE_DROPDOWN_FIELDS',
  RESCHEDULE_CLASS: 'RESCHEDULE_CLASS',
  FETCH_COURSE_DETAILS: 'FETCH_COURSE_DETAILS',
  UPDATE_DISCUSSION_FORUM_TEXT: 'UPDATE_DISCUSSION_FORUM_TEXT',
  UPDATE_DISCUSSION_FORUM_STORY: 'UPDATE_DISCUSSION_FORUM_STORY',
  SET_FILTER: 'SET_FILTER',
  FETCH_GOLIVE_DETAILS: 'FETCH_GOLIVE_DETAILS',
  FETCH_TEMPLATES_OF_A_COURSE: 'FETCH_TEMPLATES_OF_A_COURSE',
  UPDATE_TEMPLATES: 'UPDATE_TEMPLATES',
  FETCH_TEMPLATE_DETAILS: 'FETCH_TEMPLATE_DETAILS',
  SEARCH_TOPIC_GROUP: 'SEARCH_TOPIC_GROUP',
  ADD_TEMPLATE_LESSON: 'ADD_TEMPLATE_LESSON',
  SETUP_TEMPLATE_EDIT_SESSION: 'SETUP_TEMPLATE_EDIT_SESSION',
  SAVE_TEMPLATE: 'SAVE_TEMPLATE',
  DELETE_TEMPLATE_LESSONS: 'DELETE_TEMPLATE_LESSON',
  FETCH_COURSE_TEMPLATE_FILTERS: 'FETCH_COURSE_TEMPLATE_FILTERS',
  SETUP_TEMPLATE_DATA_FOR_COURSE: 'SETUP_TEMPLATE_DATA_FOR_COURSE',
  ADD_TEMPLATE_COURSE: 'ADD_TEMPLATE_COURSE',
  EDIT_TEMPLATE_COURSE: 'EDIT_TEMPLATE_COURSE',
  UPDATE_TEMPLATE_COURSE_FIELDS: 'UPDATE_TEMPLATE_COURSE_FIELDS',
  UPDATE_SCHEDULE_WEEK_DAY: 'UPDATE_SCHEDULE_WEEK_DAY',
  FETCH_TEMPLATE_COURSE_DETAILS: 'FETCH_TEMPLATE_COURSE_DETAILS',
  SET_SCHEDULE_OF_TEMPLATE_COURSE: 'SET_SCHEDULE_OF_TEMPLATE_COURSE',
  SETUP_TEMPLATE_DATA_IN_EDU_COURSE: 'SETUP_TEMPLATE_DATA_IN_EDU_COURSE',
  EDIT_TEMPLATE_COURSE_LESSON_WITHOUT_UID:
    'EDIT_TEMPLATE_COURSE_LESSON_WITHOUT_UID',
  FILL_DURATION_IN_SCHEDULE: 'FILL_DURATION_IN_SCHEDULE',
  DELETE_COURSE_TEMPLATE: 'DELETE_COURSE_TEMPLATE',
  CLEAR_TEMPLATE_SESSION: 'CLEAR_TEMPLATE_SESSION',
  CLEAR_TEMPLATES_STATE: 'CLEAR_TEMPLATES_STATE',
  SET_TEMPLATE_LESSON_DURATION: 'SET_TEMPLATE_LESSON_DURATION',
  CLEAR_TEMPLATE_DATA: 'CLEAR_TEMPLATE_DATA',
  DELETE_TEMPLATE_LESSONS_FROM_COURSE: 'DELETE_TEMPLATE_LESSONS_FROM_COURSE',
  GET_FEEDBACK_FOR_COURSE_TEMPLATE: 'GET_FEEDBACK_FOR_COURSE_TEMPLATE',
  SUBMIT_FEEDBACK_FOR_COURSE_TEMPLATE: 'SUBMIT_FEEDBACK_FOR_COURSE_TEMPLATE',
  SET_CLASH_SESSIONS_OF_A_COURSE: 'SET_CLASH_SESSIONS_OF_A_COURSE',
  SET_LESSON_REFS: 'SET_LESSON_REFS',
  SET_DRAG_INFO: 'SET_DRAG_INFO',
  SET_TEMPLATE_REORDERED_QUIZ_LESSONS: 'SET_TEMPLATE_REORDERED_QUIZ_LESSONS',
  GET_FEEDBACK_FOR_EDUCATOR_DASHBOARD: 'GET_FEEDBACK_FOR_EDUCATOR_DASHBOARD',
  SUBMIT_FEEDBACK_FOR_EDUCATOR_DASHBOARD:
    'SUBMIT_FEEDBACK_FOR_EDUCATOR_DASHBOARD',
  SET_AUTO_DPP_KNOW_MORE_CARD_STATUS: 'SET_AUTO_DPP_KNOW_MORE_CARD_STATUS',
  CLEAR_COURSE_UID: 'CLEAR_COURSE_UID',
  REMOVE_CLASH_SESSIONS: 'REMOVE_CLASH_SESSIONS',
  FETCH_CONTENT_SLIDE_LANGUAGES: 'FETCH_CONTENT_SLIDE_LANGUAGES',
  GET_ON_BOARDING_PREFERENCES: 'GET_ON_BOARDING_PREFERENCES',
  CREATE_LIVE_SUPPORT_TICKET: 'CREATE_LIVE_SUPPORT_TICKET',
  GET_RESCHEDULE_INFO: 'GET_RESCHEDULE_INFO',
  RESCHEDULE_REQUEST_WARNING: 'RESCHEDULE_REQUEST_WARNING',
  RESET_RESCHEDULE_REQUEST_WARNING: 'RESET_RESCHEDULE_REQUEST_WARNING',
  GET_FAQ_LEVELS: 'GET_FAQ_LEVELS',
  GET_LEVELS_INTRO: 'GET_LEVELS_INTRO',
  GET_META_INFO: 'GET_META_INFO',
  GET_LEVEL_INFO: 'GET_LEVEL_INFO',
  UPDATE_LEVEL_INFO: 'UPDATE_LEVEL_INFO',
  GET_LEVEL_STATS: 'GET_LEVEL_STATS',
  GET_LEVEL_WISE_EDUCATOR: 'GET_LEVEL_WISE_EDUCATOR',
  MARK_LEVEL_INFO_VISITED: 'MARK_LEVEL_INFO_VISITED',
  MARK_LEVEL_PROMOTION_VISITED: 'MARK_LEVEL_PROMOTION_VISITED',
  GET_GOALS_FOR_STATS: 'GET_GOALS_FOR_STATS',
  UPDATE_GOALS_FOR_STATS: 'UPDATE_GOALS_FOR_STATS',
  FETCH_COMMUNITY_SHARE_CARD_INFO: 'FETCH_COMMUNITY_SHARE_CARD_INFO',
  FETCH_COMMUNITY_DETAILS: 'FETCH_COMMUNITY_DETAILS',
  FETCH_COMMUNITY_UPDATE_INTRO: 'FETCH_COMMUNITY_UPDATE_INTRO',
  FETCH_OPEN_HOUSE_AWARENESS_INFO: 'FETCH_OPEN_HOUSE_AWARENESS_INFO',
  FETCH_COMMUNITY_QUIZ_AWARENESS_INFO: 'FETCH_COMMUNITY_QUIZ_AWARENESS_INFO',
  REORDER_COURSE_SESSIONS: 'REORDER_COURSE_SESSIONS',
  AUTO_SCHEDULE_COURSE_SESSIONS: 'AUTO_SCHEDULE_COURSE_SESSIONS',
  SET_AUTO_SCHEDULE_CLASHES: 'SET_AUTO_SCHEDULE_CLASHES',
  FETCH_EDUCATOR_SCHEDULER_AWARENESS_INFO:
    'FETCH_EDUCATOR_SCHEDULER_AWARENESS_INFO',
  FETCH_COMMUNITY_ONBOARDING: 'FETCH_COMMUNITY_ONBOARDING',
  SEND_MESSAGE_TO_COMMUNITY: 'SEND_MESSAGE_TO_COMMUNITY',
  MARK_SCHEDULER_INFO_VISITED: 'MARK_SCHEDULER_INFO_VISITED',
  REMOVE_SESSION_EDIT_ERROR: 'REMOVE_SESSION_EDIT_ERROR',
  GET_DPP_REMINDER_CARD_STATUS: 'GET_DPP_REMINDER_CARD_STATUS',
  FETCH_DPP_QUESTIONS: 'FETCH_DPP_QUESTIONS',
  UPDATE_DPP_QUESTION_ON_SHUFFLE: 'UPDATE_DPP_QUESTION_ON_SHUFFLE',
  UPDATE_DPP_FILTER_QUESTION_NUMBER: 'UPDATE_DPP_FILTER_QUESTION_NUMBER',
  UPDATE_DPP_FILTER_SELECTED_SUBCONCEPTS:
    'UPDATE_DPP_FILTER_SELECTED_SUBCONCEPTS',
  ADD_DPP_FILTER_DIFFICULTY: 'ADD_DPP_FILTER_DIFFICULTY',
  REMOVE_DPP_FILTER_DIFFICULTY: 'REMOVE_DPP_FILTER_DIFFICULTY',
  SELECT_ALL_DIFFICULTIES: 'SELECT_ALL_DIFFICULTIES',
  REMOVE_ALL_DIFFICULTIES: 'REMOVE_ALL_DIFFICULTIES',
  ADD_DPP_SUBCONCEPT: 'ADD_DPP_SUBCONCEPT',
  REMOVE_DPP_SUBCONCEPT: 'REMOVE_DPP_SUBCONCEPT',
  RESET_FILTER: 'RESET_FILTER',
  DELETE_PRACTICE: 'DELETE_PRACTICE',
  SET_PRACTICE_CTA: 'SET_PRACTICE_CTA',
  FETCH_DPP_QUIZ_INFO: 'FETCH_DPP_QUIZ_INFO',
  SAVE_DRAFT_DPP_QUESTION: 'SAVE_DRAFT_DPP_QUESTION',
  UPDATE_DPP_QUESTION_ORDER: 'UPDATE_DPP_QUESTION_ORDER',
  RESET_DPP_QUESTION_ORDER: 'RESET_DPP_QUESTION_ORDER',
  PUBLISH_DPP_QUIZ: 'PUBLISH_DPP_QUIZ',
  FETCH_DPP_FEEDBACK_OPTIONS: 'FETCH_DPP_FEEDBACK_OPTIONS',
  SUBMIT_DPP_PRACTICE_FEEDBACK: 'SUBMIT_DPP_PRACTICE_FEEDBACK',
  FETCH_DPP_REPORT_QUESTION_INFO: 'FETCH_DPP_REPORT_QUESTION_INFO',
  SUBMIT_REPORT_DPP_QUESTION: 'SUBMIT_REPORT_DPP_QUESTION',
  SET_COURSE_PAGE_LINK: 'SET_COURSE_PAGE_LINK',
  EDUCATOR_MODAL_TYPE: 'EDUCATOR_MODAL_TYPE',
  FETCH_COURSE_TEMPLATES_AWARENESS_INFO:
    'FETCH_COURSE_TEMPLATES_AWARENESS_INFO',
  REMOVE_SUBCONCEPT_BY_UID: 'REMOVE_SUBCONCEPT_BY_UID',
  ADD_SUBCONCEPT_BY_UID: 'ADD_SUBCONCEPT_BY_UID',
  SET_SUBCONCEPT_NORMALIZED_DATA: 'SET_SUBCONCEPT_NORMALIZED_DATA',
  FETCH_DPP_SUBCONCEPT_COUNT: 'FETCH_DPP_SUBCONCEPT_COUNT',
  SET_SELECTED_DIFFICULTY_COUNT: 'SET_SELECTED_DIFFICULTY_COUNT',
  SHARE_DPP_IN_COMMUNITY: 'SHARE_DPP_IN_COMMUNITY',
  SET_TEST_TYPE: 'SET_TEST_TYPE',
  SET_TEST_FREQUENCY: 'SET_TEST_FREQUENCY',
  FETCH_RM_EDITS_AWARENESS_INFO: 'FETCH_RM_EDITS_AWARENESS_INFO',
  FETCH_RECORDED_VIDEO_DETAILS: 'FETCH_RECORDED_VIDEO_DETAILS',
  SEND_AUTO_PROMOTION_MESSAGE: 'SEND_AUTO_PROMOTION_MESSAGE',
  DISMISS_AUTO_PROMOTION_MESSAGE: 'DISMISS_AUTO_PROMOTION_MESSAGE',
  AUTO_PROMOTION_CAMPAIGN_INFO: 'AUTO_PROMOTION_CAMPAIGN_INFO',
  UPDATE_RADIO_BOX: 'UPDATE_RADIO_BOX',
  FETCH_ALL_OFFLINE_CITIES: 'FETCH_ALL_OFFLINE_CITIES',
  FETCH_ALL_OFFLINE_CENTRES: 'FETCH_ALL_OFFLINE_CENTRES',
  UPDATE_RADIO_BOX_SESSION: 'UPDATE_RADIO_BOX_SESSION',
  FETCH_ALL_ROOMS: 'FETCH_ALL_ROOMS',
  CHANGE_ROOM_OF_A_COURSE: 'CHANGE_ROOM_OF_A_COURSE',
  UPDATE_SESSION_DROPDOWN_FIELDS: 'UPDATE_SESSION_DROPDOWN_FIELDS',
  FETCH_TA_LIVE_CLASS_URL: 'FETCH_TA_LIVE_CLASS_URL',
  UPDATE_TA_POWERED_STATE: 'UPDATE_TA_POWERED_STATE',
  FETCH_SPECIAL_CLASS_TYPES: 'FETCH_SPECIAL_CLASS_TYPES',
  UPDATE_SUB_CONCEPTS: 'UPDATE_SUB_CONCEPTS',
  REMOVE_SUB_CONCEPTS: 'REMOVE_SUB_CONCEPTS'
};

export const educatorOnboardingTypes = {
  GET_ONBOARD_USER_STATUS: 'GET_ONBOARD_USER_STATUS',
  GET_APPLICATION_DETAILS: 'GET_APPLICATION_DETAILS',
  GET_IFSC_DETAILS: 'GET_IFSC_DETAILS',
  UPDATE_APPLICATION_META: 'UPDATE_APPLICATION_META',
  SUBMIT_KYC: 'SUBMIT_KYC',
  SUBMIT_BILLING_INFO: 'SUBMIT_BILLING_INFO',
  EDIT_BILLING_INFO: 'EDIT_BILLING_INFO',
  GET_BILLING_INFO: 'GET_BILLING_INFO',
  UPLOAD_KYC_IMAGES: 'UPLOAD_KYC_IMAGES',
  SET_ACADEMIC_DETAILS: 'SET_ACADEMIC_DETAILS',
  SET_EXPERIENCE_DETAILS: 'SET_EXPERIENCE_DETAILS',
  SET_BILLING_DETAILS: 'SET_BILLING_DETAILS',
  SET_IFSC_DETAILS: 'SET_IFSC_DETAILS'
};

export const topologyTypes = {
  UPDATE_TOPOLOGIES: 'UPDATE_TOPOLOGIES',
  FETCH_TOPOLOGIES: 'FETCH_TOPOLOGIES',
  FETCH_ONBOARDING_GOALS: 'FETCH_ONBOARDING_GOALS',
  FETCH_GOAL_LANGUAGES: 'FETCH_GOAL_LANGUAGES',
  SET_GOAL_LANGUAGE: 'SET_GOAL_LANGUAGE',
  FETCH_TOPOLOGY_FEED: 'FETCH_TOPOLOGY_FEED',
  FETCH_NOTES: 'FETCH_NOTES',
  FETCH_TOPOLOGY_CHILDREN: 'FETCH_TOPOLOGY_CHILDREN',
  FETCH_TOPOLOGY_INFO: 'FETCH_TOPOLOGY_INFO',
  FETCH_TOPOLOGY_COURSE_FEED: 'FETCH_TOPOLOGY_COURSE_FEED',
  FETCH_TOPOLOGY_COURSES: 'FETCH_TOPOLOGY_COURSES',
  FETCH_TOPOLOGY_EXTRA_INFO: 'FETCH_TOPOLOGY_EXTRA_INFO',
  FETCH_TOPOLOGY_CHILDREN_OF_FREE_COURSE:
    'FETCH_TOPOLOGY_CHILDREN_OF_FREE_COURSE',
  FETCH_TOPOLOGY_OF_FREE_COURSE_FEED: 'FETCH_TOPOLOGY_OF_FREE_COURSE_FEED',
  FETCH_FREE_COURSES_OF_A_CONCEPT: 'FETCH_FREE_COURSES_OF_A_CONCEPT',
  FETCH_POPULAR_GOALS_GROUPS: 'FETCH_POPULAR_GOALS_GROUPS',
  FETCH_COURSE_STATS: 'FETCH_COURSE_STATS',
  FETCH_PRESUBSCRIBED_STATIC_CONTENT: 'FETCH_PRESUBSCRIBED_STATIC_CONTENT',
  FETCH_POST_SUBSCRIPTION_ONBOARDING_PREFERENCES:
    'FETCH_POST_SUBSCRIPTION_ONBOARDING_PREFERENCES',
  SAVE_PREFERENCE_FETCH_NEXT: 'SAVE_PREFERENCE_FETCH_NEXT',
  FETCH_ALL_ONBOARDING_PREFERENCE_OF_A_USER:
    'FETCH_ALL_ONBOARDING_PREFERENCE_OF_A_USER',
  UPDATE_GLO_STATUS: 'UPDATE_GLO_STATUS',
  FETCH_SUBSCRIPTION_GOALS: 'FETCH_SUBSCRIPTION_GOALS',
  FETCH_GOAL_BENEFITS: 'TOPOLOGY: FETCH_GOAL_BENEFITS',
  SET_GLO_PREFERENCES_UPDATED: 'SET_GLO_PREFERENCES_UPDATED',
  FETCH_NEW_SYLLABUS_FEED: 'FETCH_NEW_SYLLABUS_FEED',
  FETCH_NEET_RECORDED_CONTENT: 'FETCH_NEET_RECORDED_CONTENT',
  FETCH_GOAL_SYNONYMS: 'FETCH_GOAL_SYNONYMS'
};

export const combatTypes = {
  UPDATE_COMBAT: 'UPDATE_COMBAT',
  FETCH_CONTEST_INFO: 'FETCH_CONTEST_INFO',
  FETCH_CONTEST_RATING: 'FETCH_CONTEST_RATING',
  FETCH_MY_RATING: 'FETCH_MY_RATING',
  FETCH_UPCOMING_COMBATS_INFO: 'FETCH_UPCOMING_COMBATS_INFO',
  FETCH_COMBAT_DETAILS: 'FETCH_COMBAT_DETAILS',
  FETCH_CONTEST_ONBOARDING_PREFERENCES: 'FETCH_CONTEST_ONBOARDING_PREFERENCES',
  ENROLL_FOR_CONTEST: 'ENROLL_FOR_CONTEST',
  UPDATE_CONTEST_ONBOARDING_PREFERENCES:
    'UPDATE_CONTEST_ONBOARDING_PREFERENCES',
  FETCH_PAGINATED_COMBATS: 'FETCH_PAGINATED_COMBATS',
  COMBAT_MODAL_TYPE: 'COMBAT_MODAL_TYPE',
  FETCH_COMBAT_INFO: 'FETCH_COMBAT_INFO'
};

export const plusCoursesTypes = {
  FETCH_TEST_SERIES: 'FETCH_TEST_SERIES',
  FETCH_PLUS_HOME_FEED: 'FETCH_PLUS_HOME_FEED',
  FETCH_EBINFO_PLUS_HOME_FEED: 'FETCH_EBINFO_PLUS_HOME_FEED',
  FETCH_SEE_ALL_EBINFO: 'FETCH_SEE_ALL_EBINFO',
  FETCH_SIMILAR: 'FETCH_SIMILAR',
  FETCH_NEXT_TRACK: 'FETCH_NEXT_TRACK',
  FETCH_COURSE_UPDATES: 'FETCH_COURSE_UPDATES'
};

export const itemTypes = {
  UPDATE_ITEMS: 'UPDATE_ITEMS',
  FETCH_WATCH_HISTORY: 'FETCH_WATCH_HISTORY',
  FETCH_FREE_LESSON_INFO: 'FETCH_FREE_LESSON_INFO',
  FETCH_FREE_LESSON_LIST: 'FETCH_FREE_LESSON_LIST',
  BOOKMARK_ITEM: 'BOOKMARK_ITEM',
  FETCH_FREE_RELATED_SPECIAL_ITEMS: 'FETCH_FREE_RELATED_SPECIAL_ITEMS'
};

export const classTypes = {
  FETCH_CLASS: 'FETCH_CLASS',
  FETCH_SIMILAR_AND_OTHER_COURSES: 'FETCH_SIMILAR_AND_OTHER_COURSES',
  SEND_WATCHED: 'SEND_WATCHED',
  UPDATE_PAGEVIEW: 'UPDATE_PAGEVIEW',
  FETCH_SCHEDULED_CLASSES: 'FETCH_SCHEDULED_CLASSES',
  FETCH_CALENDAR_DATA: 'FETCH_CALENDAR_DATA_LEARNER'
};

export const subscriptionTypes = {
  FETCH_PRICINGS: 'FETCH_PRICINGS',
  VALIDATE_REFERRAL: 'VALIDATE_REFERRAL',
  VALIDATE_REFERRAL_SUBSCRIPTION: 'VALIDATE_REFERRAL_SUBSCRIPTION',
  FETCH_PAYMENT_METHODS: 'FETCH_PAYMENT_METHODS',
  FETCH_EMI_PLANS: 'FETCH_EMI_PLANS',
  FETCH_PART_PAYMENT_STATUS: 'FETCH_PART_PAYMENT_STATUS',
  ENROLL_ORDER: 'ENROLL_ORDER',
  ENROLL_INSTALLMENT_ORDER: 'ENROLL_INSTALLMENT_ORDER',
  CLEAR_ORDER: 'CLEAR_ORDER',
  CANCEL_ENROLL_ORDER: 'CANCEL_ENROLL_ORDER',
  PURCHASE_SUBSCRIPTION: 'PURCHASE_SUBSCRIPTION',
  PAYMENT_STATUS_JUSPAY: 'PAYMENT_STATUS_JUSPAY',
  SAVE_PURCHASED_SUBSCRIPTION: 'SAVE_PURCHASED_SUBSCRIPTION',
  ACTIVATE_EXTERNAL_APP: 'ACTIVATE_EXTERNAL_APP',
  STOP_PART_PAYMENT_NUDGE: 'STOP_PART_PAYMENT_NUDGE',
  SYNC_CODE_CHEF: 'SYNC_CODE_CHEF',
  FETCH_SUBSCRIPTION_STATIC_DATA: 'FETCH_SUBSCRIPTION_STATIC_DATA',
  FETCH_BUNDLE_DETAILS: 'FETCH_BUNDLE_DETAILS',
  FETCH_OFFER_LIST: 'FETCH_OFFER_LIST',
  FETCH_OFFER_FAQS: 'FETCH_OFFER_FAQS',
  FETCH_COMBO_FEATURE_POINTS: 'FETCH_COMBO_FEATURE_POINTS',
  FETCH_CASH_PAYMENT_DATA: 'FETCH_CASH_PAYMENT_DATA',
  FETCH_BATCH_GROUP_SUBSCRIPTION_INFO: 'FETCH_BATCH_GROUP_SUBSCRIPTION_INFO',
  FETCH_SUBSCRIPTION_FEEBACK_CARDS: 'FETCH_SUBSCRIPTION_FEEBACK_CARDS'
};

export const specialClassTypes = {
  FETCH_EBINFO_SEE_ALL_URL: 'FETCH_EBINFO_SEE_ALL_URL',
  FETCH_SPECIAL_CLASS_SEE_ALL_BLOCK: 'FETCH_SPECIAL_CLASS_SEE_ALL_BLOCK'
};

export const notificationTypes = {
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  MARK_NOTIFICATION_READ: 'MARK_NOTIFICATION_READ'
};

export const searchTypes = {
  FETCH_SEARCH_RESULT: 'FETCH_SEARCH_RESULT',
  FETCH_SEARCH_LIST: 'FETCH_SEARCH_LIST',
  UPDATE_RECENT_SEARCH_LOG: 'UPDATE_RECENT_SEARCH_LOG'
};

export const otherTypes = {
  FETCH_FAQS: 'FETCH_FAQS',
  FETCH_S3_SIGNIN_URL: 'FETCH_S3_SIGNIN_URL',
  SUBMIT_ISSUE: 'SUBMIT_ISSUE',
  FETCH_LEGENDS_BANNER: 'FETCH_LEGENDS_BANNER',
  FETCH_SITEMAP: 'FETCH_SITEMAP',
  FETCH_ICONIC_SUBSCRIPTION_INFO: 'FETCH_ICONIC_SUBSCRIPTION_INFO',
  FETCH_SUBSCRIPTION_FEATURES: 'FETCH_SUBSCRIPTION_FEATURES',
  FETCH_SUBSCRIPTION_HIGHLIGHTS: 'FETCH_SUBSCRIPTION_HIGHLIGHTS',
  FETCH_SUBSCRIPTION_FAQS: 'FETCH_SUBSCRIPTION_FAQS',
  FETCH_PRICE_HIKE_INFO: 'FETCH_PRICE_HIKE_INFO',
  FETCH_ONE_CAROUSEL_FEED: 'FETCH_ONE_CAROUSEL_FEED',
  FETCH_ROOT_PAGE_DATA: 'FETCH_ROOT_PAGE_DATA',
  FETCH_GOAL_DISPLAY_NAMES: 'FETCH_GOAL_DISPLAY_NAMES',
  FETCH_PRODIGY_DATA: 'FETCH_PRODIGY_DATA',
  SAVE_PRODIGY_ADDITIONAL_DATA: 'SAVE_PRODIGY_ADDITIONAL_DATA',
  VERIFY_PRODIGY_DETAILS: 'VERIFY_PRODIGY_DETAILS',
  SET_SAVED_COURSE: 'SET_SAVED_COURSE',
  SET_WEBP_SUPPORTED: 'SET_WEBP_SUPPORTED',
  PREDICT_EXAM_RANK: 'PREDICT_EXAM_RANK',
  FETCH_COMMUNITY_STATIC_DATA: 'FETCH_COMMUNITY_STATIC_DATA',
  SET_FETCH_STATE_OF_SCHEDULE_DATA_FOR_DPP:
    'SET_FETCH_STATE_OF_SCHEDULE_DATA_FOR_DPP',
  SET_INITIAL_PROPS_RUN: 'SET_INITIAL_PROPS_RUN'
};

export const salesTypes = {
  FETCH_CALL_ASSIST_FEED: 'FETCH_CALL_ASSIST_FEED',
  FETCH_COMBO_PLANS: 'FETCH_COMBO_PLANS',
  FETCH_PRICINGS: 'FETCH_PRICINGS',
  FETCH_AVL_PAYMENT_OPTS: 'FETCH_AVL_PAYMENT_OPTS',
  FETCH_LEAD_DETAILS: 'FETCH_LEAD_DETAILS',
  FETCH_STATS: 'FETCH_STATS',
  FETCH_CRM_GOALS: 'FETCH_CRM_GOALS',
  FETCH_HERO_DATA: 'FETCH_HERO_DATA',
  FETCH_SUBSCRIBED_SUBSCRIPTIONS: 'FETCH_SUBSCRIBED_SUBSCRIPTIONS',
  FETCH_FAV_EDUCATOR: 'FETCH_FAV_EDUCATOR',
  FETCH_SUGGESTED_REFFERAL: 'FETCH_SUGGESTED_REFFERAL',
  FETCH_RECOMMENDED_EDU: 'FETCH_RECOMMENDED_EDU',
  FETCH_RECOMMENDED_BATCHES: 'FETCH_RECOMMENDED_BATCHES',
  FETCH_RECOMMENDED_COURSES: 'FETCH_RECOMMENDED_COURSES',
  FETCH_RECOMMENDED_TEST_SERIES: 'FETCH_RECOMMENDED_TEST_SERIES',
  FETCH_REC_ACTIVATION_PITCH: 'FETCH_REC_ACTIVATION_PITCH',
  SEARCH_EDUCATORS: 'SEARCH_EDUCATORS',
  CLEAR_SEARCH_EDUCATORS: 'CLEAR_SEARCH_EDUCATORS',
  FETCH_LANG_FILTERS: 'FETCH_LANG_FILTERS',
  FETCH_AVL_OFFERS: 'FETCH_AVL_OFFERS',
  ACTIVATE_OFFER: 'ACTIVATE_OFFER',
  FETCH_LOAN_LEARNER_DETAILS: 'FETCH_LOAN_LEARNER_DETAILS',
  FETCH_ELIGIBLE_GOALS: 'FETCH_ELIGIBLE_GOALS',
  SUBMIT_LOAN_DATA: 'SUBMIT_LOAN_DATA',
  SUBMIT_CASH_DEPOSIT_DATA: 'SUBMIT_CASH_DEPOSIT_DATA',
  FETCH_ELIGIBLE_SUBSCRIPTIONS: 'FETCH_ELIGIBLE_SUBSCRIPTIONS',
  FETCH_MENTOR_DETAILS: 'FETCH_MENTOR_DETAILS',
  TOGGLE_LMP_AVAILABILITY: 'TOGGLE_LMP_AVAILABILITY',
  FETCH_LMP_SESSION_DETAILS: 'FETCH_LMP_SESSION_DETAILS',
  CLEAR_LMP_SESSION_DETAILS: 'CLEAR_LMP_SESSION_DETAILS',
  FETCH_LMP_FEEDBACK_OPTIONS: 'FETCH_LMP_FEEDBACK_OPTIONS',
  SUBMIT_LMP_FEEDBACK: 'SUBMIT_LMP_FEEDBACK',
  CANCEL_LMP_SESSION: 'CANCEL_LMP_SESSION',
  FETCH_ELIGIBLE_SUBSCRIPTIONS_FOR_CASH_PAYMENT:
    'FETCH_ELIGIBLE_SUBSCRIPTIONS_FOR_CASH_PAYMENT',
  VALIDATE_CASH_REFERRAL: 'VALIDATE_CASH_REFERRAL'
};

export const quizTypes = {
  UPDATE_QUESTIONS: 'UPDATE_QUESTIONS',
  FETCH_MY_QUIZ_ATTEMPTS: 'FETCH_MY_QUIZ_ATTEMPTS',
  UPDATE_SESSION_LANGUAGE: 'UPDATE_SESSION_LANGUAGE',
  FETCH_SESSION_DETAILS: 'FETCH_SESSION_DETAILS',
  CHECK_ACCESS: 'CHECK_ACCESS',
  UPLOAD_CSV: 'UPLOAD_CSV',
  FETCH_REVIEW_STATS: 'FETCH_REVIEW_STATS',
  EDIT_QUIZ: 'EDIT_QUIZ',
  FETCH_REVIEWS: 'FETCH_REVIEWS',
  FETCH_DASHBOARD_GOALS: 'FETCH_DASHBOARD_GOALS',
  UPDATE_DASHBOARD_FILTERS: 'UPDATE_DASHBOARD_FILTERS',
  FETCH_POSTREVIEW_NOTIFICATIONS: 'FETCH_POSTREVIEW_NOTIFICATIONS',
  ASSIGN_REVIEW: 'ASSIGN_REVIEW',
  FETCH_TEST_SERIES_DATA: 'FETCH_TEST_SERIES_DATA',
  FETCH_GTP_TEST_SERIES_DATA: 'FETCH_GTP_TEST_SERIES_DATA',
  UPLOAD_SUJECTIVE_ANSWERS: 'UPLOAD_SUJECTIVE_ANSWERS',
  FETCH_EVAL_DASH_STATS: 'FETCH_EVAL_DASH_STATS',
  FETCH_MY_EVALS: 'FETCH_MY_EVALS',
  FETCH_EVALUATED_COPIES: 'FETCH_EVALUATED_COPIES',
  FETCH_EVAL_DASH_GOALS: 'FETCH_EVAL_DASH_GOALS',
  ASSIGN_EVALUATION: 'ASSIGN_EVALUATION',
  SUBMIT_EVALUATION_SCORE: 'SUBMIT_EVALUATION_SCORE',
  ASSIGN_EVALUATOR: 'ASSIGN_EVALUATOR',
  ASSIGN_UPLOADER: 'ASSIGN_UPLOADER',
  FETCH_MY_UPLOADS: 'FETCH_MY_UPLOADS',
  FETCH_QUIZ_UPLOAD_DASHBOARD_GOALS: 'FETCH_QUIZ_UPLOAD_DASHBOARD_GOALS'
};

export const preSubscribedGoal = {
  FETCH_PRESUBSCRIBED_BLOCKS: 'FETCH_PRESUBSCRIBED_BLOCKS'
};

export const playerTypes = {
  OPEN_PLAYER: 'OPEN_PLAYER',
  CLOSE_PLAYER: 'CLOSE_PLAYER',
  SET_PLAYER_DATA: 'SET_PLAYER_DATA',
  SET_PLAYER_LOADED: 'SET_PLAYER_LOADED',
  FETCH_CLASS: 'FETCH_CLASS_FOR_PLAYER',
  TOGGLE_URL_MISMATCH: 'TOGGLE_URL_MISMATCH',
  RESET_PLAYER_LOADED: 'RESET_PLAYER_LOADED',
  SET_OBJECT_TYPE: 'SET_OBJECT_TYPE'
};

export const batchTypes = {
  UPDATE_BATCHES: 'UPDATE_BATCHES',
  FETCH_BATCH_INFO: 'FETCH_BATCH_INFO',
  FETCH_TOPICS_COVERED: 'FETCH_TOPICS_COVERED',
  FETCH_TEST_SERIES: 'FETCH_TEST_SERIES',
  FETCH_TOPICS_WITH_CHILDREN: 'FETCH_TOPICS_WITH_CHILDREN',
  FETCH_COURSES_IN_CONCEPT: 'FETCH_COURSES_IN_CONCEPT',
  FETCH_ITEMS_IN_COURSE: 'FETCH_ITEMS_IN_COURSE',
  ENROLL_IN_BATCH: 'ENROLL_IN_BATCH',
  FETCH_SCHEDULE: 'FETCH_SCHEDULE',
  FETCH_CALENDAR_DATA: 'FETCH_CALENDAR_DATA',
  FETCH_SCHEDULED_CLASSES: 'FETCH_SCHEDULED_CLASSES',
  FETCH_BATCHES: 'FETCH_BATCHES',
  FETCH_BATCH_UPDATES: 'FETCH_BATCH_UPDATES',
  FETCH_SIMILAR_BATCHES: 'FETCH_SIMILAR_BATCHES',
  FETCH_TRIAL_CLASSES: 'FETCH_TRIAL_CLASSES',
  FETCH_FAQS: 'FETCH_FAQS',
  FETCH_FEEDBACKS: 'FETCH_FEEDBACKS',
  ENROLL_TRIAL_CLASSES: 'ENROLL_TRIAL_CLASSES',
  FETCH_BATCH_SCHEDULE_WEEKS: 'FETCH_BATCH_SCHEDULE_WEEKS',
  UPDATE_BATCH_SCHEDULE_WEEKS: 'UPDATE_BATCH_SCHEDULE_WEEKS'
};
export const batchGroupTypes = {
  UPDATE_BATCHGROUPS: 'UPDATE_BATCHGROUPS',
  FETCH_BATCHGROUPS_INFO: 'FETCH_BATCHGROUPS_INFO',
  FETCH_BATCHGROUPS_TRIAL_CLASSES: 'FETCH_BATCHGROUPS_TRIAL_CLASSES',
  FETCH_BATCHES_GROUP: 'FETCH_BATCHES_GROUP',
  FETCH_BATCH_GROUP_INFO: 'FETCH_BATCH_GROUP_INFO',
  FETCH_ENROLLED_BATCHES: 'FETCH_ENROLLED_BATCHES',
  FETCH_BATCH_GROUP_CLASSES: 'FETCH_BATCH_GROUP_CLASSES'
};

export const classroomTypes = {
  CREATE_CLASS: 'CREATE_CLASS',
  GET_CLASS: 'GET_CLASS',
  DELETE_CLASS: 'DELETE_CLASS'
};

export const questionTypes = {
  FETCH_QUESTION_DATA: 'FETCH_QUESTION_DATA',
  FETCH_QUESTION_INFO: 'FETCH_QUESTION_INFO',
  FETCH_CONCEPT: 'FETCH_CONCEPT',
  FETCH_SUB_CONCEPTS: 'FETCH_SUB_CONCEPTS',
  TOGGLE_QUESTION_STATUS: 'TOGGLE_QUESTION_STATUS',
  EDIT_OPTION: 'EDIT_OPTION',
  SAVE_QUESTION: 'SAVE_QUESTION',
  EDIT_CONTENT: 'EDIT_CONTENT',
  EDIT_ANSWER: 'EDIT_ANSWER',
  EDIT_HINT: 'EDIT_HINT',
  EDIT_LANGUAGE: 'EDIT_LANGUAGE',
  MARK_CORRECT: 'MARK_CORRECT',
  MARK_IS_COMMON: 'MARK_IS_COMMON',
  ADD_OPTION: 'ADD_OPTION',
  REMOVE_LANGUAGE: 'REMOVE_LANGUAGE',
  SET_NUMBER_OPTIONS: 'SET_NUMBER_OPTIONS',
  SETUP_DATA: 'SETUP_DATA',
  FETCH_QUESTION_DATA_V2: 'FETCH_QUESTION_DATA_V2',
  SUBMIT_QUESTION_DETAILS: 'SUBMIT_QUESTION_DETAILS',
  SUBMIT_SOLUTION_RECORDING: 'SUBMIT_SOLUTION_RECORDING',
  SETUP_CONFIG_FOR_EDITING: 'SETUP_CONFIG_FOR_EDITING',
  SETUP_QUESTION_FOR_EDITING: 'SETUP_QUESTION_FOR_EDITING',
  FULL_RESET_QUESTION_DATA: 'FULL_RESET_QUESTION_DATA',
  RESET_QUESTION_DATA: 'RESET_QUESTION_DATA',
  UPDATE_QUESTION_DATA: 'UPDATE_QUESTION_DATA',
  UPDATE_QUESTION_CONFIG: 'UPDATE_QUESTION_CONFIG',
  FETCH_PASSAGE_RELATED_QUESTIONS: 'FETCH_PASSAGE_RELATED_QUESTIONS',
  ADD_QUESTION_SUB_TYPE: 'ADD_QUESTION_SUB_TYPE'
};

export const doubtTypes = {
  UPDATE_DOUBTS: 'UPDATE_DOUBTS',
  UPDATE_DOUBTSOLUTIONS: 'UPDATE_DOUBTSOLUTIONS',
  FETCH_DOUBT_DETAILS: 'FETCH_DOUBT_DETAILS',
  FETCH_SOLUTION_DETAILS: 'FETCH_SOLUTION_DETAILS',
  FETCH_MY_SOLUTIONS: 'FETCH_MY_SOLUTIONS',
  ASSIGN_DOUBT: 'ASSIGN_DOUBT',
  FETCH_CHOICES: 'FETCH_CHOICES',
  SKIP_DOUBT: 'SKIP_DOUBT',
  FETCH_STATS: 'FETCH_STATS',
  FETCH_HOME_TOPOLOGY: 'FETCH_HOME_TOPOLOGY',
  FETCH_TOPOLOGY_LIST: 'FETCH_TOPOLOGY_LIST',
  SET_HOME_TOPOLOGY: 'SET_HOME_TOPOLOGY',
  SUBMIT_SOLUTION: 'SUBMIT_SOLUTION',
  FEEDBACK_TO_LEARNER: 'FEEDBACK_TO_LEARNER',
  UNASSIGN_DOUBT: 'UNASSIGN_DOUBT',
  START_RECORDING: 'START_RECORDING',
  FETCH_CAMPAIGN_RULES: 'FETCH_CAMPAIGN_RULES',
  FETCH_CYCLE_EARNINGS: 'FETCH_CYCLE_EARNINGS',
  FETCH_INCENTIVE_RULES: 'FETCH_INCENTIVE_RULES',
  UPDATE_SOLUTION_DETAILS: 'UPDATE_SOLUTION_DETAILS',
  RECORDING_STARTED: 'RECORDING_STARTED',
  UPDATE_SUBMIT_TIMER: 'UPDATE_SUBMIT_TIMER'
};

export const moderatorTypes = {
  FETCH_EDUCATORS: 'FETCH_EDUCATORS',
  EDIT_ACCESS: 'EDIT_ACCESS',
  FETCH_COURSES: 'FETCH_COURSES',
  FETCH_LESSONS: 'FETCH_LESSONS',
  FETCH_POST: 'FETCH_POST'
};

export const summitTypes = {
  FETCH_EVENTS_INFO: 'FETCH_EVENTS_INFO',
  REGISTER: 'REGISTER',
  CHECK_REGISTER: 'CHECK_REGISTER',
  FETCH_SCHEDULE: 'FETCH_SCHEDULE',
  FETCH_CONTENT: 'FETCH_CONTENT'
};

export const savedTypes = {
  FETCH_SAVED_COUNT: 'FETCH_SAVED_COUNT',
  FETCH_SAVED_ENTITY: 'FETCH_SAVED_ENTITY',
  REMOVE_SAVED_ENTITY: 'REMOVE_SAVED_ENTITY',
  SAVE_ENTITY: 'SAVE_ENTITY'
};

export const helpCenterTypes = {
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_ARTICLES: 'FETCH_ARTICLES',
  FETCH_ARTICLE_INFO: 'FETCH_ARTICLE_INFO',
  FETCH_TICKETS_HISTORY: 'FETCH_TICKETS_HISTORY',
  FETCH_ME_DATA: 'FETCH_ME_DATA',
  ON_CHAT_OPEN: 'ON_CHAT_OPEN'
};

export const analytics = {
  ANALYTICS: 'ANALYTICS'
};

export const templatesTypes = {
  FETCH_TEMPLATES: 'FETCH_TEMPLATES',
  FETCH_TEMPLATE: 'FETCH_TEMPLATE',
  RESET_TEMPLATE_FORM: 'RESET_TEMPLATE_FORM',
  FETCH_GOAL_TEMPLATES: 'FETCH_GOAL_TEMPLATES',
  UPDATE_TEMPLATE_SETTINGS: 'UPDATE_TEMPLATE_SETTINGS',
  UPDATE_TEMPLATE_DETAILS: 'UPDATE_TEMPLATE_DETAILS',
  UPDATE_TEMPLATE_EVALUATION_CRITERIA: 'UPDATE_TEMPLATE_EVALUATION_CRITERIA',
  ADD_OR_UPDATE_TEMPLATE_SECTION: 'ADD_OR_UPDATE_TEMPLATE_SECTION',
  UPDATE_TEMPLATE_INSTRUCTIONS: 'UPDATE_TEMPLATE_INSTRUCTIONS',
  SET_TEMPLATE_ERRORS: 'SET_TEMPLATE_ERRORS',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  UPDATE_OR_CREATE_TEMPLATE: 'UPDATE_OR_CREATE_TEMPLATE',
  CREATE_TEST_FOR_TEMPLATE: 'CREATE_TEST_FOR_TEMPLATE',
  FETCH_TEST_TEMPLATE_DETAILS: 'FETCH_TEST_TEMPLATE_DETAILS',
  CREATE_QUIZ_IN_TEMPLATE: 'CREATE_QUIZ_IN_TEMPLATE',
  EDIT_QUIZ_IN_TEMPLATE: 'EDIT_QUIZ_IN_TEMPLATE',
  FETCH_TEST_STATE: 'FETCH_TEST_STATE',
  CLEAR_TEMPLATE_FIELDS: 'CLEAR_TEMPLATE_FIELDS',
  UPDATE_EDUCATOR_SLIDES: 'UPDATE_EDUCATOR_SLIDES'
};

export const driveTypes = {
  FETCH_DRIVE_CONTENT: 'FETCH_DRIVE_CONTENT',
  CREATE_FOLDER: 'CREATE_FOLDER',
  DELETE_DRIVE_ITEM: 'DELETE_DRIVE_ITEM',
  FETCH_ANCESTERS: 'FETCH_ANCESTERS',
  FETCH_ITEM_DETAILS: 'FETCH_ITEM_DETAILS',
  UPDATE_DRIVE_ITEM: 'UPDATE_DRIVE_ITEM',
  SEARCH_FILES: 'SEARCH_FILES',
  FETCH_DRIVE_ITEM_DETAILS: 'FETCH_DRIVE_ITEM_DETAILS'
};

export const practiceSessionTypes = {
  PRACTICE_MODAL_TYPE: 'PRACTICE: PRACTICE_MODAL_TYPE',
  FETCH_PRACTICE_TOPOLOGIES: 'FETCH_PRACTICE_TOPOLOGIES',
  FETCH_LEARNER_LEVEL_DETAILS: 'FETCH_LEARNER_LEVEL_DETAILS',
  FETCH_PRACTICE_SESSION: 'FETCH_PRACTICE_SESSION',
  CREATE_PRACTICE_SESSION: 'CREATE_PRACTICE_SESSION',
  FINISH_PRACTICE_SESSION: 'FINISH_PRACTICE_SESSION',
  FINISH_PRACTICE_QUESTION_REVIEW: 'FINISH_PRACTICE_QUESTION_REVIEW',
  SUBMIT_PRACTICE_SESSION_ANSWER: 'SUBMIT_PRACTICE_SESSION_ANSWER',
  GET_NEXT_PRACTICE_QUESTION: 'GET_NEXT_PRACTICE_QUESTION',
  SET_SELECTED_PRACTICE: 'SET_SELECTED_PRACTICE',
  SET_PRACTICE_ANSWERS: 'SET_PRACTICE_ANSWERS',
  SET_PRACTICE_QUESTION_COUNT: 'SET_PRACTICE_QUESTION_COUNT',
  SET_PRACTICE_SEEN_HINT: 'SET_PRACTICE_SEEN_HINT',
  SETUP_NEXT_PRACTICE_QUESTION: 'SETUP_NEXT_PRACTICE_QUESTION',
  RESET_PRACTICE_SESSION: 'RESET_PRACTICE_SESSION',
  FETCH_PRACTICE_FEEDBACK_OPTIONS: 'FETCH_PRACTICE_FEEDBACK_OPTIONS',
  SUBMIT_PRACTICE_FEEDBACK: 'SUBMIT_PRACTICE_FEEDBACK',
  FETCH_PRACTICE_PYQ_FILTERS: 'FETCH_PRACTICE_PYQ_FILTERS',
  FETCH_CLASS_PRACTICE_SESSION: 'PRACTICE: FETCH_CLASS_PRACTICE_SESSION',
  SET_REMINDER_DEFAULT_DATA: 'SET_REMINDER_DEFAULT_DATA',
  FETCH_PRACTICE_REMINDER: 'PRACTICE: FETCH_PRACTICE_REMINDER',
  SUBMIT_PRACTICE_REMINDER: 'PRACTICE: SUBMIT_PRACTICE_REMINDER',
  DELETE_PRACTICE_REMINDER: 'PRACTICE: DELETE_PRACTICE_REMINDER',
  DISMISS_PRACTICE_REMINDER: 'PRACTICE: DISMISS_PRACTICE_REMINDER',
  FETCH_PRACTICE_AVAILABLE_LANGUAGES: 'FETCH_PRACTICE_AVAILABLE_LANGUAGES',
  SET_LANGUAGE_PREFERENCE: 'SET_LANGUAGE_PREFERENCE'
};

export const educatorLiveMentoringTypes = {
  SLOT_PREFERENCES: 'LIVE_MENTORING: SLOT_PREFERENCES',
  FETCH_SESSIONS: 'LIVE_MENTORING: FETCH_SESSIONS',
  FETCH_PREFERENCES: 'LIVE_MENTORING: FETCH_PREFERENCES',
  FETCH_SESSION_DETAILS: 'LIVE_MENTORING: FETCH_SESSION_DETAILS',
  FETCH_FEEDBACK_SUMMARY: 'LIVE_MENTORING: FETCH_FEEDBACK_SUMMARY',
  CANCEL_SESSION: 'CANCEL_SESSION',
  FETCH_MAKE_CALL: 'LIVE_MENTORING: FETCH_MAKE_CALL',
  FETCH_CALL_STATUS: 'LIVE_MENTORING: FETCH_CALL_STATUS',
  FETCH_LEARNER_CALL_LIST: 'LIVE_MENTORING: FETCH_LEARNER_CALL_LIST',
  FETCH_LEARNER_WATCH_TIME: 'LIVE_MENTORING: FETCH_LEARNER_WATCH_TIME',
  FETCH_LEARNER_PRACTICE_INFO: 'LIVE_MENTORING:FETCH_LEARNER_PRACTICE_INFO',
  FETCH_LEARNER_TEST_INFO: 'LIVE_MENTORING:FETCH_LEARNER_TEST_INFO',
  FETCH_LEARNER_LIVE_CLASS_INFO: 'LIVE_MENTORING:FETCH_LEARNER_LIVE_CLASS_INFO',
  FETCH_PREVIOUS_NOTES: 'LIVE_MENTORING:FETCH_PREVIOUS_NOTES',
  FETCH_ADDABLE_NOTES_INFO: 'LIVE_MENTORING:FETCH_ADDABLE_NOTES_INFO',
  FETCH_MENTOR_NOTES: 'LIVE_MENTORING:FETCH_MENTOR_NOTES'
};

export const freePracticeSessionTypes = {
  FETCH_FREE_PRACTICE_TOPOLOGIES: 'FETCH_FREE_PRACTICE_TOPOLOGIES',
  FETCH_FREE_PRACTICE_TOPOLOGY_LIST: 'FETCH_FREE_PRACTICE_TOPOLOGY_LIST',
  FETCH_FREE_PRACTICE_QUESTIONS: 'FETCH_FREE_PRACTICE_QUESTIONS',
  UPDATE_FREE_PRACTICE_QUESTION_COUNT: 'UPDATE_FREE_PRACTICE_QUESTION_COUNT'
};

export const learnerDoubtsAndSolutionsTypes = {
  FETCH_CATALOG_ENTITY_DATA:
    'LEARNER_DOUBTS_AND_SOLUTIONS: FETCH_CATALOG_ENTITY_DATA',
  FETCH_CATALOGUE_TYPES: 'LEARNER_DOUBTS_AND_SOLUTIONS: FETCH_CATALOGUES_TYPES',
  FETCH_CATALOGUE_DETAILS:
    'LEARNER_DOUBTS_AND_SOLUTIONS: FETCH_CATALOGUE_DETAILS',
  FETCH_CATALOGUE_ENTITY_DETAILS:
    'LEARNER_DOUBTS_AND_SOLUTIONS: FETCH_CATALOGUE_ENTITY_DETAILS',
  FETCH_QUESTIONS_LIST: 'LEARNER_DOUBTS_AND_SOLUTIONS: FETCH_QUESTIONS_LIST',
  FETCH_QUESTION_META_DATA:
    'LEARNER_DOUBTS_AND_SOLUTIONS: FETCH_QUESTION_META_DATA',
  FETCH_QUESTION_PAPER_LIST:
    'LEARNER_DOUBTS_AND_SOLUTIONS: FETCH_QUESTION_PAPER_LIST'
};

export const createQuizTypes = {
  CREATE_QUIZ_CHECK_ACCESS: 'CREATE_QUIZ_CHECK_ACCESS',
  CREATE_QUIZ_FETCH_QUIZ_DEATILS: 'CREATE_QUIZ_FETCH_QUIZ_DEATILS',
  CREATE_QUIZ_FETCH_QUIZ_QUESTIONS: 'CREATE_QUIZ_FETCH_QUIZ_QUESTIONS',
  CREATE_QUIZ_FETCH_REVIEW_DETAILS: 'CREATE_QUIZ_FETCH_REVIEW_DETAILS',
  CREATE_QUIZ_FINISH_REVIEW: 'CREATE_QUIZ_FINISH_REVIEW',
  CREATE_QUIZ_UPDATE_SECTIONAL_QUESTION:
    'CREATE_QUIZ_UPDATE_SECTIONAL_QUESTION',
  CREATE_QUIZ_UPDATE_QUIZ_INSTRUCTIONS: 'CREATE_QUIZ_UPDATE_QUIZ_INSTRUCTIONS',
  CREATE_QUIZ_MARK_PDF_UPLOADED: 'CREATE_QUIZ_MARK_PDF_UPLOADED',
  CREATE_QUIZ_DELETE_SUBJECTIVE_PDF: 'CREATE_QUIZ_DELETE_SUBJECTIVE_PDF',
  CREATE_QUIZ_CREATE_OR_UPDATE_ISSUES: 'CREATE_QUIZ_CREATE_OR_UPDATE_ISSUES',
  CREATE_QUIZ_FETCH_REVIEW_NOTIFICATIONS:
    'CREATE_QUIZ_FETCH_REVIEW_NOTIFICATIONS',
  CREATE_QUIZ_RESOLVE_ISSUES: 'CREATE_QUIZ_RESOLVE_ISSUES',
  CREATE_QUIZ_LEARNER_RESOLVE_ISSUES: 'CREATE_QUIZ_LEARNER_RESOLVE_ISSUES',
  CREATE_QUIZ_FETCH_QUIZ_AUTO_CONFIGS: 'CREATE_QUIZ_FETCH_QUIZ_AUTO_CONFIGS',
  CREATE_QUIZ_SAVE_QUIZ_AUTO_CONFIGS: 'CREATE_QUIZ_SAVE_QUIZ_AUTO_CONFIGS',
  CREATE_QUIZ_FETCH_TOPOLOGY: 'CREATE_QUIZ_FETCH_TOPOLOGY',
  CREATE_QUIZ_SETUP_INITIAL_AUTO_CONFIGS:
    'CREATE_QUIZ_SETUP_INITIAL_AUTO_CONFIGS',
  CREATE_QUIZ_UPDATE_QUIZ_AUTO_CONFIGS: 'CREATE_QUIZ_UPDATE_QUIZ_AUTO_CONFIGS',
  CREATE_QUIZ_DELETE_QUIZ_AUTO_CONFIGS: 'CREATE_QUIZ_DELETE_QUIZ_AUTO_CONFIGS',
  CREATE_QUIZ_UPDATE_SELECTED_TOPICS: 'CREATE_QUIZ_UPDATE_SELECTED_TOPICS',
  CREATE_QUIZ_UPDATE_SELECTED_CONCEPTS: 'CREATE_QUIZ_UPDATE_SELECTED_CONCEPTS',
  CREATE_QUIZ_UPDATE_SYLLABUS_QUESTION_COUNT:
    'CREATE_QUIZ_UPDATE_SYLLABUS_QUESTION_COUNT',
  CREATE_QUIZ_UPDATE_TOPIC_QUESTION_COUNT:
    'CREATE_QUIZ_UPDATE_TOPIC_QUESTION_COUNT',
  CREATE_QUIZ_MODAL_TYPES: 'CREATE_QUIZ_MODAL_TYPES',
  CREATE_QUIZ_DELETE_QUESTION: 'CREATE_QUIZ_DELETE_QUESTION',
  CREATE_QUIZ_REPLACE_QUESTION: 'CREATE_QUIZ_REPLACE_QUESTION',
  CREATE_QUIZ_REORDER_QUESTION: 'CREATE_QUIZ_REORDER_QUESTION',
  CREATE_QUIZ_FETCH_QUESTION_FEEDBACK_OPTIONS:
    'CREATE_QUIZ_FETCH_QUESTION_FEEDBACK_OPTIONS',
  CREATE_QUIZ_REPORT_CREATED_QUESTION: 'CREATE_QUIZ_REPORT_CREATED_QUESTION'
};

export const notesTypes = {
  FETCH_LOCALITY: 'FETCH_LOCALITY',
  CREATE_NOTES_ORDER: 'CREATE_NOTES_ORDER',
  GET_USER_STATUS: 'GET_USER_STATUS'
};

export const evaluateQuizTypes = {
  EVALUATE_QUIZ_FETCH_DETAILS: 'EVALUATE_QUIZ_FETCH_DETAILS',
  EVALUATE_QUIZ_FETCH_QUESTIONS: 'EVALUATE_QUIZ_FETCH_QUESTIONS',
  EVALUATE_QUIZ_FETCH_CRITERIA: 'EVALUATE_QUIZ_FETCH_CRITERIA',
  EVALUATE_QUIZ_FETCH_FEEDBACK_CHOICES: 'EVALUATE_QUIZ_FETCH_FEEDBACK_CHOICES',
  EVALUATE_QUIZ_SKIP_EVALUATION_FEEDBACK:
    'EVALUATE_QUIZ_SKIP_EVALUATION_FEEDBACK',
  EVALUATE_QUIZ_ADD_ADVICE_TO_LEARNER: 'EVALUATE_QUIZ_ADD_ADVICE_TO_LEARNER',
  EVALUATE_QUIZ_ADD_CRITERIA_SCORE: 'EVALUATE_QUIZ_ADD_CRITERIA_SCORE',
  EVALUATE_QUIZ_ADD_MARKS_TO_QUESTION: 'EVALUATE_QUIZ_ADD_MARKS_TO_QUESTION',
  EVALUATE_QUIZ_MARK_QUESTION_AS_SKIPPED:
    'EVALUATE_QUIZ_MARK_QUESTION_AS_SKIPPED',
  EVALUATE_QUIZ_SUBMIT_SCORE: 'EVALUATE_QUIZ_SUBMIT_SCORE',
  EVALUATE_QUIZ_REMOVE_EVALUATION: 'EVALUATE_QUIZ_REMOVE_EVALUATION'
};

export const iconicOnboardingTypes = {
  FETCH_ICONIC_ONBOARDING_INFO: 'FETCH_ICONIC_ONBOARDING_INFO',
  FETCH_USER_DETAILS: 'FETCH_USER_DETAILS',
  BOOK_WELCOME_SESSION: 'BOOK_WELCOME_SESSION',
  UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS'
};

export const oneToOneLivementoring = {
  FETCH_SESSIONS_META: 'FETCH_SESSIONS_META',
  FETCH_SESSION_DETAILS: 'oneToOneLivementoring:FETCH_SESSION_DETAILS',
  CANCEL_SESSION_WITH_REASON:
    'oneToOneLivementoring:CANCEL_SESSION_WITH_REASON',
  BOOK_WELCOME_SESSION: 'oneToOneLivementoring:BOOK_WELCOME_SESSION',
  FETCH_LMP_ONBOARDING_PREFERENCES: 'FETCH_LMP_ONBOARDING_PREFERENCES',
  SET_ONBOARDING_PREFERNCES: 'SET_ONBOARDING_PREFERNCES',
  CHECK_SESSION_CONFLICT: 'CHECK_SESSION_CONFLICT',
  CONFIRM_SESSION: 'CONFIRM_SESSION'
};

export const plannerTypes = {
  SET_CURRENT_DATE: 'PLANNER: SET_CURRENT_DATE',
  RESET_EVENT_DATA: 'PLANNER: RESET_EVENT_DATA',
  SET_EVENT_DATA: 'PLANNER: SET_EVENT_DATA',
  SET_MULTIPLE_EVENT_DATA: 'PLANNER: SET_MULTIPLE_EVENT_DATA',
  SET_IS_FIRST_RUN: 'PLANNER: SET_IS_FIRST_RUN',
  SET_DATA: 'PLANNER: SET_DATA',
  SET_DATA_V2: 'PLANNER: SET_DATA_V2',
  RESET: 'PLANNER: RESET',
  SET_IS_INITIAL_SETUP_COMPLETE: 'PLANNER: SET_IS_INITIAL_SETUP_COMPLETE',
  SET_IS_FINAL_SETUP_COMPLETE: 'PLANNER: SET_IS_FINAL_SETUP_COMPLETE',
  SET_IS_EVERY_TIME_TASKS_SETUP_COMPLETE:
    'PLANNER: SET_IS_EVERY_TIME_TASKS_SETUP_COMPLETE',
  FETCH_DATA_IN_BACKGROUND_IN_PROGRESS: 'FETCH_DATA_IN_BACKGROUND_IN_PROGRESS',
  CORRUPTED_PLANNER_DATA: 'CORRUPTED_PLANNER_DATA',
  CANCEL_LMP_SESSION: 'PLANNER: CANCEL_LMP_SESSION',
  UPDATE_BROWSE_CARD: 'PLANNER: UPDATE_BROWSE_CARD',
  UPDATE_FEATURE_CARDS: 'PLANNER: UPDATE_FEATURE_CARDS',
  UPDATE_FEATURE_AWARENESS_CARD_CLICK: 'PLANNER: FEATURE AWARENESS CARD CLICK'
};

export const groupTypes = {
  FETCH_GROUPS_INFO: 'FETCH_GROUPS_INFO',
  FETCH_GROUPS_LEADERBOARD: 'FETCH_GROUPS_LEADERBOARD',
  FETCH_GROUP_MEMBER_STATUS: 'FETCH_GROUP_MEMBER_STATUS',
  FETCH_GROUP_MEMBER_DETAILS: 'FETCH_GROUP_MEMBER_DETAILS',
  FETCH_INVITEE_INFO: 'FETCH_INVITEE_INFO',
  JOIN_INVITE: 'JOIN_INVITE',
  TOGGLE_INVITATION_RECEIVED: 'TOGGLE_INVITATION_RECEIVED',
  TOGGLE_GROUP_ONBOARDING_MESSAGE: 'TOGGLE_GROUP_ONBOARDING_MESSAGE',
  FETCH_GROUPS_ITEM_INFO: 'FETCH_GROUPS_ITEM_INFO'
};

export const doubtsOnWeb2 = {
  GET_MY_DOUBTS: 'DOW2: GET_MY_DOUBTS',
  GET_DOUBT_CONFIGS: 'DOW2: GET_DOUBT_CONFIGS',
  GET_DOUBT_DETAILS: 'DOW2: GET_DOUBT_DETAILS',
  GET_ALL_DOUBTS: 'DOW2: GET_ALL_DOUBTS',
  FETCH_ALL_FILTERS: 'DOW2: FETCH_ALL_FILTERS',
  SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
  SET_SUBMIT_DOUBT_DETAILS: 'DOW2: SUBMIT_DOUBT_DETAILS',
  FETCH_SUBJECTS_AND_LANGUAGES: 'DOW2: FETCH_SUBJECTS_AND_LANGUAGES',
  SUBMIT_DOUBT: 'DOW2: SUBMIT_DOUBT',
  SET_LIMIT_FLAG: 'SET_LIMIT_FLAG',
  GET_FEEDBACK_CHOICES: 'DOW2: GET_FEEDBACK_CHOICES',
  SET_SELECTED_FEEDBACK_CHOICE: 'DOW2: SET_SELECTED_FEEDBACK_CHOICE',
  SET_SELECTED_DOUBT_SOLUTION: 'DOW2: SET_SELECTED_DOUBT_SOLUTION',
  SUBMIT_FEEDBACK: 'DOW2: SUBMIT_FEEDBACK',
  SET_FEEDBACK_DRAWER_FLAG: 'DOW: SET_FEEDBACK_DRAWER_FLAG',
  SET_FEEDBACK_SUBMITED: 'DOW: SET_FEEDBACK_SUBMITED',
  SUBMIT_FOR_REVIEW: 'DOW2: SUBMIT FOR REVIEW',
  SET_INSTANT_MATCH_FLAG: 'DOW2: SET INSTANT MATCH',
  SET_IS_SOLUTION_WATCHED: 'DOW2: SET IS SOLUTION WATCHED',
  SET_DOUBT_CONFIG: 'SET_DOUBT_CONFIG',
  SAVE_LANGUAGE_PREFERENCE: 'SAVE_LANGUAGE_PREFERENCE',
  UPDATE_DOUBT_SUBJECT: 'UPDATE_DOUBT_SUBJECT',
  UPDATE_SUBJECT_SELECTED_FLOW: 'UPDATE_SUBJECT_SELECTED_FLOW',
  FETCH_BLUE_DOT_FOR_NAV_ITEMS: 'FETCH_BLUE_DOT_FOR_NAV_ITEMS'
};

export const rankPredictor = {
  GET_RANK_PREDICTOR_SUGGESTIONS: 'GET_RANK_PREDICTOR_SUGGESTIONS',
  GET_RANK_PREDICTOR_HTML: 'GET_RANK_PREDICTOR_HTML',
  GET_RANK_PREDICTOR_RANK: 'GET_RANK_PREDICTOR_RANK'
};

export const storeTypes = {
  UPDATE_STORE: 'UPDATE_STORE'
};
